<template>
  <div id="eurovision-score-page" style="width: calc(100%);" :class="{'mobile': isMobile()}">
    <TitleHelper :title="'Eurovision scorer'"></TitleHelper>
    <div class="scores">
      <div v-for="(val,n) in scores" :key="'score'+n" :id="'score-'+n" class="score">{{val}}</div>
    </div>
    <div class="song-wrapper">
      <div class="songs">
        <div v-for="(val,n) in songs" :key="'song'+n" :id="'song-'+n" class="song" @click="releaseScore(val, n)" :class="{'winner': winner.length > 0 && winner.includes(val.country.code)}">
          <div><country-flag :country="val.country.code" size="normal" style="margin-top:-8px;" /><span class="country-name">{{val.country.name}}</span></div>
          <div style="font-weight: bold; position: relative;">
            <i style="z-index: 2; position: relative;">{{val.title}}</i>
          </div>
          <div style="z-index: 2; position: relative;">{{val.artist}}</div>
          <div v-if="val.score" class="user-score">
            <span class="score-ball">{{val.score}}</span>
          </div>
          <div v-if="val.total" class="total-score">
            <span class="total-ball">{{val.total}}</span>
          </div>
          <div class="song-position">
            {{n+1}}
          </div>
        </div>
      </div>
      <div style="height: 150px;"></div>
    </div>
  </div>
</template>

<script>
import TitleHelper from '../../helpers/vueTitleHelper.vue';
import CountryFlag from 'vue-country-flag';
import gsap from "gsap";
import Draggable from "gsap/Draggable";
gsap.registerPlugin(Draggable);

export default {
  name: "EurovisionScorer",
  beforeCreate: function() {
    document.body.className = 'eurovision';
  },
  data() {
    return {
      version: 13,
      scores: [1,2,3,4,5,6,7,8,10,12],
      winner: [],
      songs: [
        {'country':{'code':'cy', 'name':'Cyprus'}, 'title':'El diablo', 'artist':'Elena Tsagrinou', 'score': null},
        {'country':{'code':'al', 'name':'Albania'}, 'title':'Karma', 'artist':'Anxhela Peristeri', 'score': null},
        {'country':{'code':'il', 'name':'Israel'}, 'title':'Set Me Free', 'artist':'Eden Alene', 'score': null},
        {'country':{'code':'be', 'name':'Belgium'}, 'title':'The Wrong Place', 'artist':'Hooverphonic', 'score': null},
        {'country':{'code':'ru', 'name':'Russia'}, 'title':'Russian Woman', 'artist':'Manizha', 'score': null},
        {'country':{'code':'mt', 'name':'Malta'}, 'title':'Je me casse', 'artist':'Destiny', 'score': null},
        {'country':{'code':'pt', 'name':'Portugal'}, 'title':'Love Is on My Side', 'artist':'The Black Mamba', 'score': null},
        {'country':{'code':'rs', 'name':'Serbia'}, 'title':'Loco Loco', 'artist':'Hurricane', 'score': null},
        {'country':{'code':'gb', 'name':'United Kingdom'}, 'title':'Embers', 'artist':'James Newman', 'score': null},
        {'country':{'code':'gr', 'name':'Greece'}, 'title':'Last Dance', 'artist':'Stefania', 'score': null},
        {'country':{'code':'ch', 'name':'Switzerland'}, 'title':'Tout l\'Univers', 'artist':'Gjon\'s Tears', 'score': null},
        {'country':{'code':'is', 'name':'Iceland'}, 'title':'10 Years', 'artist':'Daði og Gagnamagnið', 'score': null},
        {'country':{'code':'es', 'name':'Spain'}, 'title':'You a quedarme', 'artist':'Blas Cantó', 'score': null},
        {'country':{'code':'md', 'name':'Moldova'}, 'title':'Sugar', 'artist':'Natalia Gordienko', 'score': null},
        {'country':{'code':'de', 'name':'Germany'}, 'title':'I Don\'t Feel Hate', 'artist':'Jendrik', 'score': null},
        {'country':{'code':'fi', 'name':'Finland'}, 'title':'Dark Side', 'artist':'Blind Channel', 'score': null},
        {'country':{'code':'bg', 'name':'Bulgaria'}, 'title':'Growing Up Is Getting Old', 'artist':'Victoria', 'score': null},
        {'country':{'code':'lt', 'name':'Lithuania'}, 'title':'Discoteque', 'artist':'The Roop', 'score': null},
        {'country':{'code':'ua', 'name':'Ukraine'}, 'title':'Шум', 'artist':'Go_A', 'score': null},
        {'country':{'code':'fr', 'name':'France'}, 'title':'Voilà', 'artist':'Barbara Pravi', 'score': null},
        {'country':{'code':'az', 'name':'Azerbaijan'}, 'title':'Mata Hari', 'artist':'Efendi', 'score': null},
        {'country':{'code':'no', 'name':'Norway'}, 'title':'Fallen Angel', 'artist':'Tix', 'score': null},
        {'country':{'code':'nl', 'name':'Netherlands'}, 'title':'Birth of a New Age', 'artist':'Jeangu Macrooy', 'score': null},
        {'country':{'code':'it', 'name':'Italy'}, 'title':'Zitti e buoni', 'artist':'Måneskin', 'score': null},
        {'country':{'code':'se', 'name':'Sweden'}, 'title':'Voices', 'artist':'Tusse', 'score': null},
        {'country':{'code':'sm', 'name':'San Marino'}, 'title':'Adrenalina', 'artist':'Senhit feat. Flo Rida', 'score': null},
      ],
    };
  },
  sockets: {
    euroScoreTotals(scores) {
      for (let country in scores) {
        for (let song of this.songs) {
          if (song['country']['code'] == country) {
            this.songs[this.songs.indexOf(song)]['total'] = scores[country];
          }
        }
      }
      this.calculateWinner();
      this.storeCookie();
      this.$forceUpdate();
    }
  },
  mounted() {
    var droppables = document.getElementsByClassName("song");
    const self = this;
    Draggable.create(".score", {
      type:"x,y", 
      edgeResistance:0.65, 
      bounds:"#eurovision-score-page",
      onDragEnd:function() {
        var i = droppables.length;
        while (--i > -1) {
          if (this.hitTest(droppables[i], '20%')) {
            self.onDrop(this.target, droppables[i]);
          }
        }
      }
    });
    this.$socket.emit('euroConnect');
    if (this.$cookies.isKey('euroScores')) {
      let scoreStore = this.$cookies.get('euroScores');
      if ('version' in scoreStore && scoreStore['version'] == this.version) {
        for (let a in scoreStore) {
          for (let song of this.songs) {
            if (a == song.country.code) {
              let pos = this.songs.indexOf(song);
              this.songs[pos]['score'] = scoreStore[a]
            }
          }
          for (let score of this.scores) {
            if (scoreStore[a] == score) {
              let pos = this.scores.indexOf(score);
              let obj = document.getElementById('score-'+pos.toString());
              obj.classList.add("hide");
            }
          }
        }
      }
    }
  },
  components: {
    TitleHelper,
    CountryFlag
  },
  methods: {
    storeCookie() {
      let scoreStore = {}
      for (let song of this.songs) {
        if (song.score) {
          scoreStore[song.country.code] = song.score;
        }
      }
      scoreStore['version'] = this.version;
      this.$cookies.set('euroScores', scoreStore);
    },
    calculateWinner() {
      let winner = [];
      let best = 0;
      for (let song of this.songs) {
        if (song['total'] > best) {
          best = song['total']
          winner = [ song['country']['code'] ];
        } else if (song['total'] == best && song['total'] != 0) {
          winner.push (song['country']['code']);
        }
      }
      this.winner = winner;
    },
    onDrop(dragged, dropped) {
      let scoreChanges = {}
      dragged.classList.add("hide");
      var scoreVal = this.scores[parseInt(dragged.id.split('-')[1])]
      var songPos = parseInt(dropped.id.split('-')[1])
      if (this.songs[songPos]['score'] != null) {
        var oldScorePos = this.scores.indexOf(this.songs[songPos]['score']);
        var oldScoreVal = this.scores[oldScorePos]
        var oldScore = document.getElementById('score-'+oldScorePos.toString());
        oldScore.classList.remove('hide');
        scoreChanges[this.songs[songPos]['country']['code']] = -oldScoreVal + scoreVal;
        gsap.set(oldScore, {clearProps:"transform"});
      } else {
        scoreChanges[this.songs[songPos]['country']['code']] = scoreVal;
      }
      this.songs[songPos]['score'] = scoreVal;
      this.$socket.emit('euroScoreUpdate', scoreChanges);
      gsap.fromTo(dropped, {opacity:1}, {duration: 0.1, opacity:0, repeat:3, yoyo:true});
    },
    isMobile: function() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    releaseScore(song, n) {
      if (song.score) {
        let scoreChanges = {}
        let oldScorePos = this.scores.indexOf(song.score);
        let oldScoreVal = this.scores[oldScorePos];
        let oldScore = document.getElementById('score-'+oldScorePos.toString());
        oldScore.classList.remove('hide');
        scoreChanges[song.country.code] = -oldScoreVal;
        this.$socket.emit('euroScoreUpdate', scoreChanges);
        gsap.set(oldScore, {clearProps:"transform"});
        this.songs[n]['score'] = null;
        let flashSong = document.getElementById('song-'+n.toString());
        gsap.fromTo(flashSong, {opacity:1}, {duration: 0.1, opacity:0, repeat:3, yoyo:true});
      }
    }
  },
  computed: {
  },
  watch: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#eurovision-score-page {
  height: calc(100vh);
  background-color: black;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  overflow: hidden;
  display: block;
}

#eurovision-score-page.mobile {
  min-height: calc(100vh - 40px);
}

.scores {
  display: table;
  height: 30px;
  padding:20px;
}

.score {
  display: table-cell;
  background-color: yellow;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.song-wrapper {
  overflow: scroll;
  max-height: calc( 100% - 70px );
}

.songs {
  display: flex;
  flex-wrap: wrap;
}

.song {
  background-color: darkblue;
  margin: 5px;
  padding: 10px;
  width: 210px;
  color: #ccff66;
  border-radius: 5px;
  border: 2px solid #2222ff;
  position: relative;
}

.song.winner {
  background: rgb(2,0,36);
  background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(59,97,130,1) 50%, rgba(4,23,23,1) 100%);
}

.mobile .song {
  width: calc(100vw - 40px);
}

.hide {
  display: none;
}

.user-score {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 30px;
  display: inline-grid;
  opacity: 0.6;
  z-index: 2;
}

.score-ball {
  background-color: yellow;
  color: black;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  padding-top: 5px;
}

.total-score {
  position: absolute;
  bottom: 2px;
  right: 2px;
  display: inline-grid;
  z-index: 2;
}

.total-ball {
  background-color: black;
  color: red;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  padding: 5px 10px 4px 10px;
}

.song-position {
  position: relative;
  height: 0px;
  top: -80px;
  opacity: 0.25;
  text-align: right;
  font-weight: bold;
  font-size: 70px;
  color: black;
  right: 6px;
  z-index: 0;
}

.country-name {
  position: relative;
  left: 5px;
  top: -4px;
  z-index: 2;
}

</style>

